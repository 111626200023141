


import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/shared/Notification.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';


import { BaiViet } from 'src/app/shared/BaiViet.model';
import { BaiVietService } from 'src/app/shared/BaiViet.service';
import { DanhMucBaiViet } from 'src/app/shared/DanhMucBaiViet.model';
import { DanhMucBaiVietService } from 'src/app/shared/DanhMucBaiViet.service';
import { BaiVietDetailComponent } from '../bai-viet-detail/bai-viet-detail.component';
import { DanhMucNgonNguService } from 'src/app/shared/DanhMucNgonNgu.service';


@Component({
  selector: 'app-bai-viet',
  templateUrl: './bai-viet.component.html',
  styleUrls: ['./bai-viet.component.css']
})
export class BaiVietComponent implements OnInit {

  @ViewChild('BaiVietSort') BaiVietSort: MatSort;
  @ViewChild('BaiVietPaginator') BaiVietPaginator: MatPaginator;

  constructor(
    private dialog: MatDialog,
    public NotificationService: NotificationService,

    public BaiVietService: BaiVietService,
    public DanhMucBaiVietService: DanhMucBaiVietService,
    public DanhMucNgonNguService: DanhMucNgonNguService,
  ) { }

  ngOnInit(): void {
    this.DanhMucBaiVietSearch();
    this.BaiVietSearch();
    this.DanhMucNgonNguSearch()
  }

  DanhMucBaiVietSearch() {
    this.DanhMucBaiVietService.ComponentGetAllToListAsync();
  }
  DanhMucNgonNguSearch(){
  this.DanhMucNgonNguService.ComponentGetAllToListAsync();
}

  BaiVietSearch() {
    this.BaiVietService.SearchByParentIDNotEmpty(this.BaiVietSort, this.BaiVietPaginator);    
  }
  Sitemap() {   
    this.BaiVietService.IsShowLoading = true;
    this.BaiVietService.SitemapAsync().subscribe(
      res => {  
        this.BaiVietService.IsShowLoading = false;      
      },
      err => {
        this.BaiVietService.IsShowLoading = false;      
      }
    );
  }

  BaiVietDelete(element: BaiViet) {
    this.BaiVietService.BaseParameter.ID = element.ID;
    this.NotificationService.warn(this.BaiVietService.ComponentDeleteByParentIDNotEmpty(this.BaiVietSort, this.BaiVietPaginator));
  }

  BaiVietAdd(ID: number) {
    this.BaiVietService.FileToUpload = null;
    this.BaiVietService.BaseParameter.ID = ID;
    this.BaiVietService.GetByIDAsync().subscribe(
      res => {
        this.BaiVietService.FormData = res as BaiViet;
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = environment.DialogConfigWidth;
        dialogConfig.data = { ID: ID };
        const dialog = this.dialog.open(BaiVietDetailComponent, dialogConfig);
        dialog.afterClosed().subscribe(() => {
          this.BaiVietSearch();
        });
      },
      err => {
      }
    );
  }
}
