<div class="custom-tabs-container">
  <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">
    <li class="nav-item" role="presentation">
      <a class="nav-link active" id="tab-BaiVietDetail" data-bs-toggle="tab" href="#BaiVietDetail" role="tab"
        aria-controls="BaiVietDetail" aria-selected="true">
        <span class="badge bg-info" style="font-size: 16px">Bài viết [{{BaiVietService.FormData.ID}}]</span>
      </a>
    </li>
    <li class="nav-item" role="presentation">
      <a style="cursor: pointer" class="nav-link" id="tab-Close" data-bs-toggle="tab" (click)="Close()" role="tab"
        aria-controls="Close" aria-selected="false">
        <span class="badge bg-danger" style="font-size: 16px"><i class="bi bi-x-lg"></i></span>
      </a>
    </li>
  </ul>
  <div class="tab-content" id="customTabContent">
    <div class="tab-pane fade show active" id="BaiVietDetail" role="tabpanel">
      <div class="row gx-4">
        <div class="col-lg-5 col-sm-12 col-12">
          <div class="row gx-4">
            <div class="col-lg-6 col-sm-12 col-6">
              <a style="width: 100%" title="Lưu thay đổi" (click)="BaiVietSave()" class="btn btn-info"><i
                  class="bi bi-sd-card"></i> Lưu thay đổi</a>
            </div>
            <div class="col-lg-6 col-sm-12 col-6" style="text-align: center;">
              <a style="width: 100%" title="Lưu thay đổi" (click)="BaiVietCopy()" class="btn btn-info"><i
                  class="bi bi-sd-card"></i> Copy</a>
            </div>
          </div>
          <div class="col-lg-12 col-sm-12 col-12">
            <label class="form-label">Danh mục</label>
            <select class="form-select" [(ngModel)]="BaiVietService.FormData.ParentID">
              <option *ngFor="let item of DanhMucBaiVietService.List" [value]="item.ID">
                {{item.Name}} [{{item.ID}}]
              </option>
            </select>
          </div>
          <div class="col-lg-12 col-sm-12 col-12">
            <label class="form-label">Ngôn ngữ</label>
            <select class="form-select" [(ngModel)]="BaiVietService.FormData.DanhMucNgonNguID">
              <option *ngFor="let item of DanhMucNgonNguService.List" [value]="item.ID">
                {{ item.Name }}
              </option>
            </select>
          </div>
          <div class="col-lg-12 col-sm-12 col-12">
            <label class="form-label">Tiêu đề</label>
            <input name="Name" [(ngModel)]="BaiVietService.FormData.Name" placeholder="Tiêu đề" type="text"
              class="form-control" />
          </div>
          <div class="col-lg-12 col-sm-12 col-12">
            <label class="form-label">Slug</label>
            <input name="Code" [(ngModel)]="BaiVietService.FormData.Code" placeholder="Slug" type="text"
              class="form-control" />
          </div>
          <div class="col-lg-12 col-sm-12 col-12">
            <label class="form-label">Tags (Cách nhau dấu phẩy)</label>
            <input name="Tags" [(ngModel)]="BaiVietService.FormData.Tags" placeholder="Tags (Cách nhau dấu phẩy)"
              type="text" class="form-control" />
          </div>
          <div class="col-lg-12 col-sm-12 col-12">
            <label class="form-label">Mô tả</label>
            <input name="Description" [(ngModel)]="BaiVietService.FormData.Description" placeholder="Mô tả" type="text"
              class="form-control" />
          </div>
          <div class="col-lg-12 col-sm-12 col-12">
            <div class="form-check form-check-inline mb-6">
              <input type="checkbox" class="form-check-input" name="Active"
                [(ngModel)]="BaiVietService.FormData.Active" />
              <label class="form-check-label" for="Active">Kích hoạt</label>
            </div>
          </div>
          <div class="col-lg-12 col-sm-12 col-12">
            <label class="form-label">Hình đại diện: </label>
            <br />
            <input type="file" (change)="ChangeFileName($event.target.files)" />
            <br />
            <br />
            <a target="_blank" href="{{BaiVietService.FormData.FileName}}"><img width="300" height="200"
                src="{{BaiVietService.FormData.FileName}}" /></a>
          </div>
          <div class="col-lg-12 col-sm-12 col-12">
            <label class="form-label">QR: </label>
            <br />
            <br />
            <a target="_blank" href="{{BaiVietService.FormData.QRCodeFileName}}"><img width="300" height="300"
                src="{{BaiVietService.FormData.QRCodeFileName}}" /></a>
          </div>
        </div>
        <div class="col-lg-5 col-sm-12 col-12">
          <ck-editor [config]="{ height: 900, width: 800 }" name="HTMLContent"
            [(ngModel)]="BaiVietService.FormData.HTMLContent" skin="moono-lisa" language="en"
            [fullPage]="true"></ck-editor>
        </div>
      </div>
      <div id="BaiVietFileDinhKem">
        <div class="card mb-4 card-info">
          <div class="card-header">
            <h5 class="card-title text-white">
              Danh sách file đính kèm
              <span *ngIf="BaiVietFileDinhKemService.List">({{BaiVietFileDinhKemService.List.length}} items)</span>
            </h5>
          </div>
          <div class="card-body">
            <div class="table-outer">
              <div class="table-responsive">
                <table *ngIf="!NotificationService.IsMobile" mat-table matSort #BaiVietFileDinhKemSort="matSort"
                  [dataSource]="BaiVietFileDinhKemService.DataSource" class="table table-striped m-0">
                  <ng-container matColumnDef="Name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                      Thông tin
                    </th>
                    <td mat-cell *matCellDef="let element">
                      <div class="row gx-4">
                        <div class="col-lg-12 col-sm-12 col-12">
                          <label class="form-label">Tiêu đề</label>
                          <input class="form-control" type="text" placeholder="Tiêu đề" name="Name{{element.ID}}"
                            [(ngModel)]="element.Name">
                        </div>
                        <div class="col-lg-12 col-sm-12 col-12">
                          <label class="form-label">Phụ đề</label>
                          <input class="form-control" type="text" placeholder="Phụ đề" name="Description{{element.ID}}"
                            [(ngModel)]="element.Description">
                        </div>
                        <div class="col-lg-12 col-sm-12 col-12">
                          <label class="form-label">URL</label>
                          <input class="form-control" type="text" placeholder="URL" name="FileName{{element.ID}}"
                            [(ngModel)]="element.FileName">
                        </div>
                        <div class="col-lg-12 col-sm-12 col-12">
                          <label class="form-label">Sắp xếp</label>
                          <input class="form-control" type="number" placeholder="0" name="SortOrder{{ element.ID }}"
                            [(ngModel)]="element.SortOrder" style="text-align: right" />
                        </div>

                      </div>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="FileName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                      Upload
                    </th>
                    <td mat-cell *matCellDef="let element">
                      <input type="file" (change)="BaiVietFileDinhKemChangeFile(element, $event.target.files)">
                      <br />
                      <br />
                      <a style="cursor: pointer;" title="{{element.Name}}" class="link-primary" target="_blank"
                        href="{{element.FileName}}"><img width="300" height="200" src="{{element.FileName}}" /></a>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="Save">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
                    <td mat-cell *matCellDef="let element">
                      <a class="btn btn-info" (click)="BaiVietFileDinhKemSave(element)"><i class="bi bi-sd-card"></i>
                      </a>&nbsp;&nbsp;&nbsp;
                      <a class="btn btn-danger" (click)="BaiVietFileDinhKemDelete(element)" *ngIf="element.ID > 0"><i
                          class="bi bi-trash"></i>
                      </a>
                    </td>
                  </ng-container>
                  <tr mat-header-row *matHeaderRowDef="BaiVietFileDinhKemService.DisplayColumns001"></tr>
                  <tr mat-row *matRowDef="let row; columns: BaiVietFileDinhKemService.DisplayColumns001"></tr>
                </table>
                <mat-paginator *ngIf="!NotificationService.IsMobile" #BaiVietFileDinhKemPaginator="matPaginator"
                  [pageSizeOptions]="[10, 20, 50, 100]" [pageSize]="6" [showFirstLastButtons]></mat-paginator>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-loading *ngIf="BaiVietService.IsShowLoading"></app-loading>