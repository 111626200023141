<div class="app-body">
  <div class="row">
    <div class="col-xxl-12">
      <div class="card" style="margin-left: 1px">
        <div class="card-body">
          <div class="custom-tabs-container">
            <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">
              <li class="nav-item" role="presentation">
                <a class="nav-link active" id="tab-BaiViet" data-bs-toggle="tab" href="#BaiViet" role="tab"
                  aria-controls="BaiViet" aria-selected="true">
                  <span class="badge bg-info" style="font-size: 16px">Bài viết</span>
                </a>
              </li>
            </ul>
            <div>       
            </div>
            <div class="tab-content" id="customTabContent">
              <div class="tab-pane fade show active" id="BaiViet" role="tabpanel">
                <div class="row gx-4">
                  <div class="col-lg-3 col-sm-12 col-12">
                    <label *ngIf="!NotificationService.IsMobile" class="form-label">Danh mục [{{BaiVietService.BaseParameter.ParentID}}]
                    </label>
                    <select class="form-select" [(ngModel)]="BaiVietService.BaseParameter.ParentID">
                      <option value="0">Tất cả</option>
                      <option *ngFor="let item of DanhMucBaiVietService.List" [value]="item.ID">
                        {{item.Name}} [{{item.ID}}]
                      </option>
                    </select>
                  </div>
                  <div class="col-lg-6 col-sm-12 col-12">
                    <label *ngIf="!NotificationService.IsMobile" class="form-label">.</label>
                    <input [(ngModel)]="BaiVietService.SearchString" placeholder="Tìm ..." class="form-control"
                      (keyup.enter)="BaiVietSearch()" />
                  </div>
                  <div class="col-lg-3 col-sm-6 col-12">
                    <label *ngIf="!NotificationService.IsMobile" class="form-label">.</label>
                    <div class="row gx-4">
                      <div class="col-lg-4 col-sm-12 col-4">
                        <a style="width: 100%" class="btn btn-success" title="Tìm" (click)="BaiVietSearch()"><i
                            class="bi bi-search"></i></a>
                      </div>
                      <div class="col-lg-4 col-sm-12 col-4">
                        <a style="width: 100%" class="btn btn-success" title="Thêm mới" (click)="BaiVietAdd(0)"><i
                            class="bi bi-plus"></i></a>
                      </div>
                      <div class="col-lg-4 col-sm-12 col-3">
                        <a style="width: 100%" class="btn btn-success" title="Sitemap" (click)="Sitemap()"><i
                            class="bi bi-hypnotize"></i></a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card mb-4 card-info">
                  <div class="card-header">
                    <h5 class="card-title text-white">
                      Danh sách
                      <span *ngIf="BaiVietService.List">({{ BaiVietService.List.length }} items)</span>
                    </h5>
                  </div>
                  <div class="card-body">
                    <div class="table-outer">
                      <div class="table-responsive">
                        <table *ngIf="!NotificationService.IsMobile" mat-table matSort #BaiVietSort="matSort"
                          [dataSource]="BaiVietService.DataSource" class="table table-striped m-0">
                          
                          <ng-container matColumnDef="STT">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                              STT
                            </th>
                            <td mat-cell *matCellDef="let element; index as i" style="text-align: center">
                              <div *ngIf="BaiVietPaginator">
                                {{
                                BaiVietPaginator.pageSize *
                                BaiVietPaginator.pageIndex +
                                i +
                                1
                                }}
                              </div>
                            </td>
                          </ng-container>
                          <ng-container matColumnDef="ID">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                              ID
                            </th>
                            <td mat-cell *matCellDef="let element">
                              {{ element.ID }}
                            </td>
                          </ng-container>
                          <ng-container matColumnDef="FileName">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                              Hình đại diện
                            </th>
                            <td mat-cell *matCellDef="let element">
                              <a style="cursor: pointer;" title="{{element.Name}}" class="link-primary"
                                (click)="BaiVietAdd(element.ID)"><img width="120" height="80"
                                  src="{{element.FileName}}" /></a>
                            </td>
                          </ng-container>
                          <ng-container matColumnDef="ParentID">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                              Danh mục
                            </th>
                            <td mat-cell *matCellDef="let element">
                              <div *ngFor="let item of DanhMucBaiVietService.List">
                                <div *ngIf="item.ID==element.ParentID">
                                  {{item.Name}}
                                </div>
                              </div>
                            </td>
                          </ng-container>
                          <ng-container matColumnDef="DanhMucNgonNguID">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Ngôn ngữ
                            </th>
                            <td mat-cell *matCellDef="let element">
                              <div *ngFor="let item of DanhMucNgonNguService.List">
                                <div *ngIf="item.ID==element.DanhMucNgonNguID">
                                  {{item.Name}}
                                </div>
                              </div>       
                            </td>
                          </ng-container>
                          <ng-container matColumnDef="Name">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                              Tiêu đề
                            </th>
                            <td mat-cell *matCellDef="let element" style="width: 600px; white-space: normal">
                              <a style="cursor: pointer; white-space: normal;" title="{{element.Name}}"
                                class="link-primary" (click)="BaiVietAdd(element.ID)"><b>{{element.Name}}</b></a>
                            </td>
                          </ng-container>                     
                          <ng-container matColumnDef="Active">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                              Kích hoạt
                            </th>
                            <td mat-cell *matCellDef="let element">
                              <div class="form-check form-check-inline">
                                <input class="form-check-input" type="checkbox" name="Active{{ element.ID }}"
                                  [(ngModel)]="element.Active" />
                              </div>
                            </td>
                          </ng-container>
                          <ng-container matColumnDef="Save">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
                            <td mat-cell *matCellDef="let element">
                              <a class="btn btn-danger" (click)="BaiVietDelete(element)" *ngIf="element.ID > 0"><i
                                  class="bi bi-trash"></i>
                              </a>
                            </td>
                          </ng-container>
                          <tr mat-header-row *matHeaderRowDef="BaiVietService.DisplayColumns003"></tr>
                          <tr mat-row *matRowDef="
                              let row;
                              columns: BaiVietService.DisplayColumns003
                            "></tr>
                        </table>
                        <mat-paginator *ngIf="!NotificationService.IsMobile" #BaiVietPaginator="matPaginator"
                          [pageSizeOptions]="[10, 20, 50, 100]" [pageSize]="6" [showFirstLastButtons]></mat-paginator>

                        <table *ngIf="NotificationService.IsMobile" mat-table matSort #BaiVietSort="matSort"
                          [dataSource]="BaiVietService.DataSource" class="table table-striped m-0">
                          <ng-container matColumnDef="STT">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                              Danh sách
                            </th>
                            <td mat-cell *matCellDef="let element; index as i">
                              <div class="row gx-4">
                                <div class="col-lg-12 col-sm-12 col-12">
                                  <label>Danh mục: </label>
                                  <select class="form-select" [(ngModel)]="element.ParentID">
                                    <option *ngFor="
                                        let item of DanhMucBaiVietService.List
                                      " [value]="item.ID">
                                      {{ item.Name }}
                                    </option>
                                  </select>
                                </div>
                                <div class="col-lg-12 col-sm-12 col-12">
                                  <label>Tiêu đề: </label>
                                  <a style="cursor: pointer" title="{{ element.Name }}" class="link-primary"
                                    style="white-space: normal" (click)="BaiVietAdd(element.ID)"><b>{{ element.Name
                                      }}</b></a>
                                </div>
                                <div class="col-lg-12 col-sm-12 col-12">
                                  <label>Hình đại diện: </label>
                                  <br />
                                  <img width="300" height="200" src="{{element.FileName}}" class="img-fluid rounded" />
                                </div>
                                <div class="row gx-4">
                                  <div class="col-lg-12 col-sm-12 col-12">
                                    <a class="btn btn-danger" style="width: 100%" (click)="BaiVietDelete(element)"
                                      *ngIf="element.ID > 0"><i class="bi bi-trash"></i>
                                    </a>
                                  </div>
                                  <br />
                                  <br />
                                </div>
                              </div>
                            </td>
                          </ng-container>
                          <tr mat-header-row *matHeaderRowDef="
                              BaiVietService.DisplayColumnsMobile
                            "></tr>
                          <tr mat-row *matRowDef="
                              let row;
                              columns: BaiVietService.DisplayColumnsMobile
                            "></tr>
                        </table>
                        <mat-paginator *ngIf="NotificationService.IsMobile" #BaiVietPaginator="matPaginator"
                          [pageSizeOptions]="[10, 20, 50, 100]" [pageSize]="10000"
                          [showFirstLastButtons]></mat-paginator>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-loading *ngIf="BaiVietService.IsShowLoading"></app-loading>