import { Injectable, NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { DanhMucBaiViet } from 'src/app/shared/DanhMucBaiViet.model';
import { BaseService } from './Base.service';
@Injectable({
  providedIn: 'root'
})
export class DanhMucBaiVietService extends BaseService {
  List: DanhMucBaiViet[] | undefined;
  ListFilter: DanhMucBaiViet[] | undefined;
  FormData!: DanhMucBaiViet;

  DisplayColumns001: string[] = ['STT', 'ID', 'ParentID', 'Name', 'SortOrder', 'Active', 'FileName', 'Save'];
  DisplayColumns002: string[] = ['STT', 'ID', 'FileName', 'ParentID', 'Name', 'Save'];
  constructor(public httpClient: HttpClient) {
    super(httpClient);
    this.Controller = "DanhMucBaiViet";
  }
}

