import { Component, OnInit, Inject, ElementRef, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificationService } from 'src/app/shared/Notification.service';

import { DanhMucBaiViet } from 'src/app/shared/DanhMucBaiViet.model';
import { DanhMucBaiVietService } from 'src/app/shared/DanhMucBaiViet.service';
import { DanhMucNgonNguService } from 'src/app/shared/DanhMucNgonNgu.service';



import { BaiViet } from 'src/app/shared/BaiViet.model';
import { BaiVietService } from 'src/app/shared/BaiViet.service';

import { BaiVietFileDinhKem } from 'src/app/shared/BaiVietFileDinhKem.model';
import { BaiVietFileDinhKemService } from 'src/app/shared/BaiVietFileDinhKem.service';

@Component({
  selector: 'app-bai-viet-detail',
  templateUrl: './bai-viet-detail.component.html',
  styleUrls: ['./bai-viet-detail.component.css']
})
export class BaiVietDetailComponent implements OnInit {


  @ViewChild('BaiVietFileDinhKemSort') BaiVietFileDinhKemSort: MatSort;
  @ViewChild('BaiVietFileDinhKemPaginator') BaiVietFileDinhKemPaginator: MatPaginator;

  constructor(
    public dialogRef: MatDialogRef<BaiVietDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,

    public NotificationService: NotificationService,

    public DanhMucBaiVietService: DanhMucBaiVietService,

    public DanhMucNgonNguService: DanhMucNgonNguService,

    public BaiVietService: BaiVietService,
    public BaiVietFileDinhKemService: BaiVietFileDinhKemService,
  ) { }

  ngOnInit(): void {
    this.DanhMucBaiVietSearch();
    this.BaiVietFileDinhKemSearch();
    this.DanhMucNgonNguSearch();
  }

  DanhMucBaiVietSearch() {
    if (this.DanhMucBaiVietService.List.length == 0) {
      this.DanhMucBaiVietService.ComponentGetAllToListAsync();
    }
  }

  DanhMucNgonNguSearch() {
    if (this.DanhMucNgonNguService.List.length == 0) {
      this.DanhMucNgonNguService.ComponentGetAllToListAsync();
    }
  }

  ChangeFileName(files: FileList) {

    if (files) {
      if (files.length > 0) {
        this.BaiVietService.FileToUpload = files;
        let fileToUpload0: File = null;
        fileToUpload0 = files.item(0);
        var reader = new FileReader();
        reader.onload = (event: any) => {
          this.BaiVietService.FormData.FileName = event.target.result;
        };
        reader.readAsDataURL(fileToUpload0);
      }
    }
  }

  BaiVietSave() {

    this.BaiVietService.IsShowLoading = true;
    this.BaiVietService.SaveAndUploadFileAsync().subscribe(
      res => {
        this.BaiVietService.FormData = res as BaiViet;
        this.NotificationService.success(environment.SaveSuccess);
        this.BaiVietService.IsShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.BaiVietService.IsShowLoading = false;
      }
    );
  }
  BaiVietCopy() {
    this.BaiVietService.FormData.ID = 0;
    this.BaiVietService.FormData.DanhMucNgonNguID = 2;
    this.BaiVietService.IsShowLoading = true;
    this.BaiVietService.SaveAndUploadFileAsync().subscribe(
      res => {
        this.BaiVietService.FormData = res as BaiViet;
        for (let i = 0; i < this.BaiVietFileDinhKemService.List.length; i++) {
          this.BaiVietFileDinhKemSave(this.BaiVietFileDinhKemService.List[i]);
        }
        this.NotificationService.success(environment.SaveSuccess);
        this.BaiVietService.IsShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.BaiVietService.IsShowLoading = false;
      }
    );
  }

  Close() {
    this.dialogRef.close();
  }

  BaiVietFileDinhKemSearch() {
    this.BaiVietFileDinhKemService.BaseParameter.ParentID = this.BaiVietService.FormData.ID;
    this.BaiVietFileDinhKemService.SearchByParentID(this.BaiVietFileDinhKemSort, this.BaiVietFileDinhKemPaginator);
  }
  BaiVietFileDinhKemSave(element: BaiVietFileDinhKem) {
    this.BaiVietService.IsShowLoading = true;
    element.ParentID = this.BaiVietService.FormData.ID;
    this.BaiVietFileDinhKemService.FormData = element;
    this.BaiVietFileDinhKemService.SaveAndUploadFileAsync().subscribe(
      res => {
        this.BaiVietFileDinhKemSearch();
        this.NotificationService.success(environment.SaveSuccess);
        this.BaiVietService.IsShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.BaiVietService.IsShowLoading = false;
      }
    );
  }
  BaiVietFileDinhKemDelete(element: BaiVietFileDinhKem) {
    this.BaiVietFileDinhKemService.BaseParameter.ID = element.ID;
    this.BaiVietFileDinhKemService.RemoveAsync().subscribe(
      res => {
        this.BaiVietFileDinhKemSearch();
        this.NotificationService.success(environment.DeleteSuccess);
        this.BaiVietService.IsShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.DeleteNotSuccess);
        this.BaiVietService.IsShowLoading = false;
      }
    );
  }


  BaiVietFileDinhKemChangeFile(element: BaiVietFileDinhKem, files: FileList) {
    if (files) {
      if (files.length > 0) {
        let fileToUpload0: File = null;
        this.BaiVietFileDinhKemService.FileToUpload = files;
        fileToUpload0 = files.item(0);
        var reader = new FileReader();
        reader.onload = (event: any) => {
          element.FileName = event.target.result;
        };
        reader.readAsDataURL(fileToUpload0);
      }
    }
  }
}
