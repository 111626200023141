import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BaiViet } from 'src/app/shared/BaiViet.model';
import { BaseService } from './Base.service';
@Injectable({
  providedIn: 'root'
})
export class BaiVietService extends BaseService {

  List: BaiViet[] | undefined;
  ListFilter: BaiViet[] | undefined;
  FormData!: BaiViet;

  DisplayColumns001: string[] = ['STT', 'ID', 'ParentID', 'Name', 'SortOrder', 'Active', 'FileName', 'Save'];
  DisplayColumns002: string[] = ['STT', 'ID', 'FileName', 'ParentID', 'Name', 'Active', 'Save'];
  DisplayColumns003: string[] = ['STT', 'ID', 'FileName', 'ParentID', 'DanhMucNgonNguID', 'Name', 'Active', 'Save'];

  constructor(public httpClient: HttpClient) {
    super(httpClient);
    this.Controller = "BaiViet";
  }
  GetByParentIDToListAsync() {
    let url = this.APIURL + this.Controller + '/GetByParentIDToListAsync';
    if (this.BaseParameter.ParentID == 0) {
      return this.GetAllToListAsync();
    }
    const formUpload: FormData = new FormData();
    formUpload.append('data', JSON.stringify(this.BaseParameter));
    return this.httpClient.post(url, formUpload);
  }
  SitemapAsync() {
    let url = this.APIURL + this.Controller + '/SitemapAsync';   
    const formUpload: FormData = new FormData();
    formUpload.append('data', JSON.stringify(this.BaseParameter));
    return this.httpClient.post(url, formUpload);
  }
}

